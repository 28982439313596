<template>
  <div>
    <base-loading v-if="!ready" />
    <div v-else-if="selecaoFazenda === null || selecaoFazenda === ''">
      <v-alert
        color="warning"
        icon="mdi-cow"
        dark
        dismissible
      >
        <span><h2>Por favor selecione uma Fazenda no canto superior direito. </h2></span>
      </v-alert>
    </div>
    <v-container
      v-else
      :style="$vuetify.breakpoint.lgAndDown ? 'width: 100%;' : 'width: 75%;'"
      fluid
    >
      <v-container>
        <v-row>
          <v-col
            md="9"
            cols="12"
          >
            <dashboard-carrosel />
          </v-col>
          <v-col
            md="3"
            cols="12"
            class="d-flex align-center"
          >
            <produtor-infos />
          </v-col>
        </v-row>
      </v-container>
      <v-container style="width: 100%; margin-bottom: 60px;">
        <v-row>
          <div
            style="font-size: 32px; font-weight: 700; color: rgba(24, 59, 148, 1); margin-left: 20px; margin-top: 10px;"
          >
            Tem no Clube
          </div>
          <v-divider
            class="custom-divider"
            height="10px"
            max-height="5px"
            style="background-color:  rgba(24, 59, 148, 1); height: 3px; max-height: 50px; margin-top: 35px; margin-left: 30px;"
          />
        </v-row>
        <v-row>
          <v-col
            md="4"
            sm="6"
            cols="12"
          >
            <a
              class="image-container"
              @click="$router.push({ path: 'cdl/volumes/grafico' }).catch(() => {})"
            >
              <p
                class="container-text"
                :style="titleSize"
              >
                Volume
              </p>
              <v-img
                src="@/assets/menu-produtor/C1.png"
              />
            </a>
          </v-col>
          <v-col
            md="4"
            sm="6"
            cols="12"
          >
            <a
              class="image-container"
              @click="$router.push({ path: 'cdl/qualidades/grafico' }).catch(() => {})"
            >
              <p
                class="container-text"
                :style="titleSize"
              >
                Qualidade
              </p>
              <v-img
                src="@/assets/menu-produtor/C2.png"
              />
            </a>
          </v-col>
          <v-col
            md="4"
            sm="6"
            cols="12"
          >
            <a
              class="image-container"
              @click="$router.push({ path: 'loja' }).catch(() => {})"
            >
              <p
                :style="titleSize"
                class="container-text"
              >
                Promoções
              </p>
              <v-img
                src="@/assets/menu-produtor/C3.png"
              />
            </a>
          </v-col>
          <v-col
            md="4"
            sm="6"
            cols="12"
          >
            <a
              class="image-container"
              @click="$router.push({ path: 'loja/produtos' }).catch(() => {})"
            >
              <p
                :style="titleSize"
                class="container-text"
              >
                Produtos
              </p>
              <v-img
                src="@/assets/menu-produtor/C4.png"
              />
            </a>
          </v-col>
          <v-col
            md="4"
            sm="6"
            cols="12"
          >
            <a
              class="image-container"
              @click="dialog = true"
            >
              <p
                :style="titleSize"
                class="container-text"
              >
                Extrato
              </p>
              <v-img
                src="@/assets/menu-produtor/C5.png"
              />
            </a>
            <v-dialog
              v-model="dialog"
              max-width="290"
              hide-overlay
              scrollable
            >
              <v-card>
                <v-toolbar
                  color="primary"
                  dark
                >
                  Extrato
                </v-toolbar>
                <v-card-text>
                  Em construção.
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    color="primary"
                    text
                    @click="dialog = false"
                  >
                    Close
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
          <v-col
            md="4"
            sm="6"
            cols="12"
          >
            <a
              href="http://www.clubedoprodutor.com.br"
              target="_blank"
              class="image-container"
            >
              <p
                :style="titleSize"
                class="container-text"
              >
                Academia
              </p>
              <v-img
                src="@/assets/menu-produtor/C6.png"
              />
            </a>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'
  import DashboardCarrosel from '../../components/Dashboards/DashboardCarrosel.vue'
  import ProdutorInfos from '../../components/Dashboards/ProdutorInfos.vue'
  import DashboardStore, { BOOTSTRAP_PRODUTOR } from '@/store/modules/dashboard'

  export default {
    name: 'DashboardProdutor',
    components: {
      DashboardCarrosel,
      ProdutorInfos,
    },
    data () {
      return {
        dialog: false,
      }
    },
    computed: {
      ...mapState({ user: state => state.user }),
      ...mapState('dashboard', ['ready', 'dashboard']),
      ...mapState('fazendas', ['selecaoFazenda']),
      ...mapGetters(['hasPermission']),
      profile () {
        const { avatar, nome, description, role } = this.$user.get()
        return {
          avatar,
          nome,
          description,
          role,
        }
      },
      titleSize () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 'font-size: 32px; font-weight: 700; width: 180px'
          case 'sm': return 'font-size: 28px; font-weight: 700; width: 330px'
          case 'md': return 'font-size: 22px; font-weight: 700; width: 300px'
          case 'lg': return 'font-size: 28px; font-weight: 700; width: 380px'
          case 'xl': return 'font-size: 28px; font-weight: 700; width: 380px'
          case 'xxl': return 'font-size: 28px; font-weight: 700; width: 380px'
          default: return 'color: white;'
        }
      },
    },
    created () {
      if (!this.$store.hasModule('dashboard')) { this.$store.registerModule('dashboard', DashboardStore) }
      if (this.selecaoFazenda !== null) {
        this.BOOTSTRAP_PRODUTOR({ id: this.selecaoFazenda.id })
      }
    },
    methods: {
      ...mapActions('dashboard', [BOOTSTRAP_PRODUTOR]),
      getDate () {
        const dataAtual = new Date()
        const mes = dataAtual.getMonth()
        const meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
        const mesAtual = meses[mes]
        const anoAtual = dataAtual.getFullYear()
        return 'Total de ' + mesAtual + ' de ' + anoAtual
      },
    },
  }
</script>

<style lang="scss">
  .image-container {
    position: relative;
    transition: 0.3s;
    display: block;
  }

  .image-container:hover {
    transform: rotate(5deg)
  }

  .container-text {
    color: white;
    position: absolute;
    z-index: 1;
    bottom: 10px;
    left: 50px;
    font-weight: 700;
    width: 120px;
  }
</style>
