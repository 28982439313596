<template>
  <v-carousel
    center-active
    hide-delimiter-background
    show-arrows
    height="100%"
    style="position: relative;"
    class="carousel"
  >
    <v-carousel-item
      v-for="(item,i) in getImageByRole()"
      :key="i"
      :src="item.src"
    >
      <div
        class="dashboard-carousel-text"
      >
        <div
          v-if="i === 0"
          :style="usernameSize"
        >
          Olá {{ profile.nome }}
        </div>
        <div :style="usernameSize">
          {{ item.text }}
        </div>
      </div>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
  export default {
    name: 'DashboardCarrosel',
    components: {
    },
    data () {
      return {
        produtorItems: [
          { src: require('../../assets/carousel-images/Carrossel-Web-Produtor-1.png'), text: 'Bem Vindo ao Clube.' },
          { src: require('../../assets/carousel-images/Carrossel-Web-Produtor-2.png'), text: 'Confira as Campanhas da sua região!' },
          { src: require('../../assets/carousel-images/Carrossel-Web-Produtor-3.png'), text: 'Confira as ofertas da semana.' },
          { src: require('../../assets/carousel-images/Carrossel-Web-Produtor-4.png'), text: 'Fique por dentro das notícias do setor.' },
        ],
        tecnicoItems: [
          { src: require('../../assets/carousel-images/Carrossel-Web-Tecnico-1.png'), text: 'Bem Vindo ao Clube.' },
          { src: require('../../assets/carousel-images/Carrossel-Web-Tecnico-2.png'), text: 'Visite seus produtores, orientando sobre a qualidade do leite!' },
          { src: require('../../assets/carousel-images/Carrossel-Web-Tecnico-3.png'), text: 'Atingir suas metas é essencial para garantir o sucesso.' },
          { src: require('../../assets/carousel-images/Carrossel-Web-Tecnico-4.png'), text: 'Destaque os benefícios das ofertas e campanhas para os produtores.' },
        ],
      }
    },
    computed: {
      profile () {
        const { nome } = this.$user.get()
        return {
          nome,
        }
      },
      usernameSize () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 'font-size: 12px; font-weight: 700; width: 180px'
          case 'sm': return 'font-size: 20px; font-weight: 700; width: 330px'
          case 'md': return 'font-size: 20px; font-weight: 700; width: 300px'
          case 'lg': return 'font-size: 28px; font-weight: 700; width: 380px'
          case 'xl': return 'font-size: 28px; font-weight: 700; width: 380px'
          case 'xxl': return 'font-size: 28px; font-weight: 700; width: 380px'
          default: return 'color: white;'
        }
      },
    },
    methods: {
      getImageByRole () {
        if (this.$store.state.user.roles[0].slug === 'tecnico') {
          return this.tecnicoItems
        } else {
          return this.produtorItems
        }
      },
    },
  }
</script>
<style scooped>

.dashboard-carousel-text {
  position: absolute;
  top: 50%;
  left: 8%;
  color: white
}

.v-carousel__controls {
  height: 70px;
}

</style>
