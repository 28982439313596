<template>
  <v-row style="width: 100%; height: 100%;">
    <v-col
      cols="6"
      md="12"
      :class="$vuetify.breakpoint.mdAndUp ? 'pl-0 pt-0' : 'pt-0'"
    >
      <v-card
        class="mt-0 mt-0 d-flex justify-center align-center"
        style="background-color: rgba(24, 59, 148, 1); color: white; width: 100%; height: 100%; gap:5%; padding: 5%; text-align: right; border-radius: 11px"
        elevation="5"
      >
        <v-icon
          color="white"
          :size="iconSize"
        >
          mdi-chart-areaspline
        </v-icon>
        <div>
          <div :style="textSize">
            {{ calculoVolume.volumeTotalMensal }} L
          </div>
          <div :style="textSizeDown">
            Total {{ calculoVolume.label }}
          </div>
        </div>
      </v-card>
    </v-col>

    <v-col
      cols="6"
      md="12"
      :class="$vuetify.breakpoint.mdAndUp ? 'pl-0 pt-0' : 'pt-0'"
    >
      <v-card
        class="mt-0 d-flex justify-center align-center"
        style="background-color: rgba(24, 59, 148, 1); color: white; width: 100%; height: 100%; gap: 5%; padding: 5%; text-align: right; border-radius: 11px"
        elevation="5"
      >
        <v-icon
          :size="iconSize"
          color="white"
        >
          mdi-chart-pie
        </v-icon>
        <div>
          <div :style="textSize">
            {{ calculoVolume.mediaMes }} L
          </div>
          <div :style="textSizeDown">
            Média Diária
          </div>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
  import { mapState } from 'vuex'
  import { currencyFormatter } from '../../utils/formatter'

  export default {
    name: 'ProdutorInfos',
    data () {
      return {
      }
    },
    computed: {
      ...mapState('dashboard', ['ready', 'dashboard']),
      calculoVolume () {
        const dataAtual = new Date()
        const mesAtual = dataAtual.getMonth()
        const anoAtual = dataAtual.getFullYear().toString().slice(-2)
        const mesesAbreviados = [
          'Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun',
          'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
        const data = `${mesesAbreviados[mesAtual]}/${anoAtual}`
        const dashboardValue = this.dashboard.volume.data.series[0]
        const valorEncontrado = dashboardValue.find(item => item.meta === data)
        if (!valorEncontrado) {
          return { mediaMes: 0, volumeTotalMensal: 0, label: data }
        }
        const diasNoMes = new Date(anoAtual, mesAtual + 1, 0).getDate()
        const mediaMes = currencyFormatter(parseFloat(valorEncontrado.value / diasNoMes), 0)
        const valorTotalMes = currencyFormatter(valorEncontrado.value, 0)
        return { mediaMes: mediaMes, volumeTotalMensal: valorTotalMes || 0, label: data }
      },
      textSize () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 'font-weight: 700; font-size: 4vw;'
          case 'sm': return 'font-weight: 700; font-size: 3vw'
          case 'md': return 'font-weight: 700; font-size: 2vw'
          case 'lg': return 'font-weight: 700; font-size: 2vw'
          case 'xl': return 'font-weight: 700; font-size: 2vw'
          case 'xxl': return 'font-weight: 700; font-size: 2vw'
          default: return 'color: white;'
        }
      },
      textSizeDown () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 'font-size: 3vw'
          case 'sm': return 'font-size: 2vw'
          case 'md': return 'font-size: 16px'
          case 'lg': return 'font-size: 18px'
          case 'xl': return 'font-size: 18px'
          case 'xxl': return 'font-size: 18px'
          default: return 'font-size: 16px'
        }
      },
      iconSize () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return '10vw'
          case 'sm': return '9vw'
          case 'md': return '5vh'
          case 'lg': return '7vh'
          case 'xl': return '7vh'
          case 'xxl': return '7vh'
          default: return '50'
        }
      },
    },
  }
</script>
